import React, { useContext } from "react"
import Footer from "./footer"
import Navbar from "./navbar"
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import '@formatjs/intl-relativetimeformat/dist/locale-data/en'; 
import '@formatjs/intl-relativetimeformat/dist/locale-data/ar'; 
import localEn from '../messages/en.json'
import localAr from '../messages/ar.json'
import { IntlProvider } from 'react-intl'
import { LangContext } from "../context/langContext"


const Layout = ({ children }) => {

  const { lang } = useContext(LangContext);

  return (
    <IntlProvider locale={lang} messages={lang === 'en' ? localEn : localAr}>
      <Global lang={lang}>
        <Helmet>
          <html lang={lang} dir={lang === 'en' ? 'ltr' : 'rtl'} />
        </Helmet>
        <Navbar />
        <main>{children}</main>
        <Footer />
      </Global>
    </IntlProvider>
  )
}

const Global = styled.div`
    font-family: 'Roboto', Chonburi, sans-serif;
    ${({ lang }) => lang === 'ar' && `
        font-family: Almarai, sans-serif;
        text-align: right
    `}
`

export default Layout
