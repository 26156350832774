import img_0 from './images/img_0.png'

export default {
  "v": "5.5.2",
  "fr": 25,
  "ip": 0,
  "op": 52,
  "w": 1920,
  "h": 1178,
  "nm": "Comp 1",
  "ddd": 0,
  "assets": [
    {
      "id": "image_0",
      "w": 180,
      "h": 180,
      "u": "",
      "p": img_0,
      "e": 0
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 2,
      "nm": "aL-Motamizoon Logo.ai",
      "cl": "ai",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            589,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            90,
            90,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "hasMask": true,
      "masksProperties": [
        {
          "inv": false,
          "mode": "n",
          "pt": {
            "a": 0,
            "k": {
              "i": [
                [
                  0,
                  0
                ],
                [
                  15,
                  23
                ],
                [
                  -24,
                  17
                ],
                [
                  -12,
                  -17
                ],
                [
                  24,
                  -22
                ],
                [
                  3,
                  7
                ],
                [
                  -49,
                  2
                ],
                [
                  -0.663,
                  -10.609
                ],
                [
                  112,
                  10
                ]
              ],
              "o": [
                [
                  0,
                  0
                ],
                [
                  -15,
                  -23
                ],
                [
                  24,
                  -17
                ],
                [
                  12,
                  17
                ],
                [
                  -24,
                  22
                ],
                [
                  -3,
                  -7
                ],
                [
                  49,
                  -2
                ],
                [
                  1,
                  16
                ],
                [
                  -112,
                  -10
                ]
              ],
              "v": [
                [
                  53.5,
                  168
                ],
                [
                  25,
                  153
                ],
                [
                  26,
                  23
                ],
                [
                  156,
                  24
                ],
                [
                  156,
                  155
                ],
                [
                  95,
                  166
                ],
                [
                  121,
                  86
                ],
                [
                  166.5,
                  141.5
                ],
                [
                  76,
                  167
                ]
              ],
              "c": false
            },
            "ix": 1
          },
          "o": {
            "a": 0,
            "k": 100,
            "ix": 3
          },
          "x": {
            "a": 0,
            "k": 0,
            "ix": 4
          },
          "nm": "Mask 1"
        }
      ],
      "ef": [
        {
          "ty": 22,
          "nm": "Stroke",
          "np": 13,
          "mn": "ADBE Stroke",
          "ix": 1,
          "en": 1,
          "ef": [
            {
              "ty": 10,
              "nm": "Path",
              "mn": "ADBE Stroke-0001",
              "ix": 1,
              "v": {
                "a": 0,
                "k": 1,
                "ix": 1
              }
            },
            {
              "ty": 7,
              "nm": "All Masks",
              "mn": "ADBE Stroke-0010",
              "ix": 2,
              "v": {
                "a": 0,
                "k": 0,
                "ix": 2
              }
            },
            {
              "ty": 7,
              "nm": "Stroke Sequentially",
              "mn": "ADBE Stroke-0011",
              "ix": 3,
              "v": {
                "a": 0,
                "k": 1,
                "ix": 3
              }
            },
            {
              "ty": 2,
              "nm": "Color",
              "mn": "ADBE Stroke-0002",
              "ix": 4,
              "v": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 4
              }
            },
            {
              "ty": 0,
              "nm": "Brush Size",
              "mn": "ADBE Stroke-0003",
              "ix": 5,
              "v": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": [
                        0.833
                      ],
                      "y": [
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167
                      ],
                      "y": [
                        0.167
                      ]
                    },
                    "t": 2,
                    "s": [
                      29.8
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833
                      ],
                      "y": [
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167
                      ],
                      "y": [
                        0.167
                      ]
                    },
                    "t": 10.372,
                    "s": [
                      17.8
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833
                      ],
                      "y": [
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167
                      ],
                      "y": [
                        0.167
                      ]
                    },
                    "t": 17.069,
                    "s": [
                      20.4
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833
                      ],
                      "y": [
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167
                      ],
                      "y": [
                        0.167
                      ]
                    },
                    "t": 39.953,
                    "s": [
                      20.4
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833
                      ],
                      "y": [
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167
                      ],
                      "y": [
                        0.167
                      ]
                    },
                    "t": 43.303,
                    "s": [
                      21.5
                    ]
                  },
                  {
                    "t": 50,
                    "s": [
                      20.4
                    ]
                  }
                ],
                "ix": 5
              }
            },
            {
              "ty": 0,
              "nm": "Brush Hardness",
              "mn": "ADBE Stroke-0004",
              "ix": 6,
              "v": {
                "a": 0,
                "k": 0.95,
                "ix": 6
              }
            },
            {
              "ty": 0,
              "nm": "Opacity",
              "mn": "ADBE Stroke-0005",
              "ix": 7,
              "v": {
                "a": 0,
                "k": 1,
                "ix": 7
              }
            },
            {
              "ty": 0,
              "nm": "Start",
              "mn": "ADBE Stroke-0008",
              "ix": 8,
              "v": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": [
                        0.833
                      ],
                      "y": [
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167
                      ],
                      "y": [
                        0.167
                      ]
                    },
                    "t": 2,
                    "s": [
                      0
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833
                      ],
                      "y": [
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167
                      ],
                      "y": [
                        0.167
                      ]
                    },
                    "t": 19.581,
                    "s": [
                      100
                    ]
                  },
                  {
                    "t": 32.1396484375,
                    "s": [
                      100
                    ]
                  }
                ],
                "ix": 8
              }
            },
            {
              "ty": 0,
              "nm": "End",
              "mn": "ADBE Stroke-0009",
              "ix": 9,
              "v": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": [
                        0.833
                      ],
                      "y": [
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167
                      ],
                      "y": [
                        0.167
                      ]
                    },
                    "t": 19.86,
                    "s": [
                      0
                    ]
                  },
                  {
                    "i": {
                      "x": [
                        0.833
                      ],
                      "y": [
                        0.833
                      ]
                    },
                    "o": {
                      "x": [
                        0.167
                      ],
                      "y": [
                        0.167
                      ]
                    },
                    "t": 32.419,
                    "s": [
                      0
                    ]
                  },
                  {
                    "t": 50,
                    "s": [
                      100
                    ]
                  }
                ],
                "ix": 9
              }
            },
            {
              "ty": 7,
              "nm": "Spacing",
              "mn": "ADBE Stroke-0006",
              "ix": 10,
              "v": {
                "a": 0,
                "k": 15,
                "ix": 10
              }
            },
            {
              "ty": 7,
              "nm": "Paint Style",
              "mn": "ADBE Stroke-0007",
              "ix": 11,
              "v": {
                "a": 0,
                "k": 3,
                "ix": 11
              }
            }
          ]
        }
      ],
      "ip": 0,
      "op": 52,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "aL-Motamizoon Logo Outlines",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 20,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            589,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            90,
            90,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "hasMask": true,
      "masksProperties": [
        {
          "inv": false,
          "mode": "n",
          "pt": {
            "a": 0,
            "k": {
              "i": [
                [
                  0,
                  0
                ],
                [
                  0.083,
                  0.128
                ],
                [
                  -0.133,
                  0.094
                ],
                [
                  -0.067,
                  -0.094
                ],
                [
                  0.133,
                  -0.122
                ],
                [
                  0.017,
                  0.039
                ],
                [
                  -0.272,
                  0.011
                ],
                [
                  -0.004,
                  -0.059
                ],
                [
                  0.622,
                  0.056
                ]
              ],
              "o": [
                [
                  0,
                  0
                ],
                [
                  -0.083,
                  -0.128
                ],
                [
                  0.133,
                  -0.094
                ],
                [
                  0.067,
                  0.094
                ],
                [
                  -0.133,
                  0.122
                ],
                [
                  -0.017,
                  -0.039
                ],
                [
                  0.272,
                  -0.011
                ],
                [
                  0.006,
                  0.089
                ],
                [
                  -0.622,
                  -0.056
                ]
              ],
              "v": [
                [
                  0.339,
                  0.922
                ],
                [
                  0.139,
                  0.85
                ],
                [
                  0.144,
                  0.128
                ],
                [
                  0.867,
                  0.133
                ],
                [
                  0.867,
                  0.861
                ],
                [
                  0.528,
                  0.922
                ],
                [
                  0.672,
                  0.478
                ],
                [
                  0.95,
                  0.656
                ],
                [
                  0.422,
                  0.928
                ]
              ],
              "c": false
            },
            "ix": 1
          },
          "o": {
            "a": 0,
            "k": 100,
            "ix": 3
          },
          "x": {
            "a": 0,
            "k": 0,
            "ix": 4
          },
          "nm": "Mask 1"
        }
      ],
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -29.603
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -29.601,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -3.238,
                          3.241
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          15.191
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -15.189,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -15.194
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -5.706,
                          -11.256
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          29.593,
                          0
                        ]
                      ],
                      "o": [
                        [
                          -29.599,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          29.596
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -3.159,
                          -3.156
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -15.188,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -15.194
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          15.185,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          11.346,
                          5.864
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -29.603
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -67.402,
                          -126.258
                        ],
                        [
                          -121.077,
                          -72.579
                        ],
                        [
                          -121.077,
                          0.002
                        ],
                        [
                          -67.4,
                          53.678
                        ],
                        [
                          -55.417,
                          53.678
                        ],
                        [
                          -68.105,
                          40.995
                        ],
                        [
                          -54.656,
                          27.549
                        ],
                        [
                          -67.4,
                          27.549
                        ],
                        [
                          -94.949,
                          0.002
                        ],
                        [
                          -94.949,
                          -72.579
                        ],
                        [
                          -67.402,
                          -100.132
                        ],
                        [
                          5.188,
                          -100.132
                        ],
                        [
                          32.732,
                          -72.579
                        ],
                        [
                          32.732,
                          -53.477
                        ],
                        [
                          58.865,
                          -27.182
                        ],
                        [
                          58.865,
                          -72.579
                        ],
                        [
                          5.188,
                          -126.258
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ind": 1,
                  "ty": "sh",
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          -29.601
                        ],
                        [
                          -2.851,
                          -6.664
                        ],
                        [
                          -6.482,
                          0
                        ],
                        [
                          0,
                          8.123
                        ],
                        [
                          -15.192,
                          0
                        ],
                        [
                          0,
                          -15.188
                        ],
                        [
                          14.16,
                          -1.384
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          1.674,
                          -1.672
                        ],
                        [
                          -0.719,
                          -0.717
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -6.784,
                          3.618
                        ],
                        [
                          -3.479,
                          14.861
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          13.362,
                          12.39
                        ],
                        [
                          4.231,
                          2.208
                        ],
                        [
                          8.702,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          7.29
                        ],
                        [
                          1.598,
                          0
                        ],
                        [
                          -6.13,
                          -5.196
                        ],
                        [
                          0,
                          -15.188
                        ],
                        [
                          15.185,
                          0
                        ],
                        [
                          0,
                          14.241
                        ],
                        [
                          -0.097,
                          0.008
                        ],
                        [
                          -0.003,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.722,
                          0.725
                        ],
                        [
                          1.658,
                          1.66
                        ],
                        [
                          1.13,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -0.005,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          7.821,
                          -0.498
                        ],
                        [
                          13.491,
                          -7.201
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          3.18,
                          -17.766
                        ],
                        [
                          -3.461,
                          -3.195
                        ],
                        [
                          -7.708,
                          -4.024
                        ],
                        [
                          -29.601,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -48.497,
                          0.002
                        ],
                        [
                          -44.158,
                          21.017
                        ],
                        [
                          -12.536,
                          21.018
                        ],
                        [
                          -22.363,
                          0.002
                        ],
                        [
                          5.188,
                          -27.544
                        ],
                        [
                          32.732,
                          0.002
                        ],
                        [
                          7.891,
                          27.415
                        ],
                        [
                          6.548,
                          27.514
                        ],
                        [
                          5.188,
                          27.549
                        ],
                        [
                          -45.419,
                          27.549
                        ],
                        [
                          -58.865,
                          40.995
                        ],
                        [
                          -46.176,
                          53.678
                        ],
                        [
                          5.188,
                          53.681
                        ],
                        [
                          8.029,
                          53.604
                        ],
                        [
                          8.161,
                          53.596
                        ],
                        [
                          8.355,
                          53.581
                        ],
                        [
                          30.363,
                          47.377
                        ],
                        [
                          57.467,
                          12.141
                        ],
                        [
                          57.607,
                          11.556
                        ],
                        [
                          58.033,
                          9.433
                        ],
                        [
                          41.521,
                          -39.464
                        ],
                        [
                          29.926,
                          -47.615
                        ],
                        [
                          5.188,
                          -53.678
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 2",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.596078455448,
                  0.596078455448,
                  0.596078455448,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  121.077,
                  126.257
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 52,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 1,
      "nm": "White Solid 1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            589,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            960,
            589,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "sw": 1920,
      "sh": 1178,
      "sc": "#ffffff",
      "ip": 0,
      "op": 52,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}