import React, { useContext } from 'react';
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby";
import Facebook from '../images/footer/Facebook.svg';
// import Twitter from '../images/footer/Twitter.svg';
// import LinkedIn from '../images/footer/LinkedIn.svg';
import arrowUp from '../images/footer/ScrollUp_Icon.svg';
import { LangContext } from "../context/langContext"

const Footer = () => {

  const data = useStaticQuery(graphql`
  query {
    img1: file(relativePath: { eq: "footer/LogoFooter.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 75) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    img1ar: file(relativePath: { eq: "footer/LogoArabic02.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    img2: file(relativePath: { eq: "footer/SweetnessLogoFooter.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    img3: file(relativePath: { eq: "footer/FacnyLogoFooter.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`)

  const { lang } = useContext(LangContext);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }


  return (
    <div className="footer">
      <div className="arrowUp">
        <img src={arrowUp} alt="" onClick={scrollToTop} width="100%" />
      </div>
        <div className="row row1 justify-content-around text-center">
          <div className="col-10 col-md-7 col-lg-4 align-self-center my-3 my-lg-0">
            {/* <img src={lang==='en'?img1:img1ar} alt="almootamayezoon" width={lang==='en'?"95%": "75%"}/> */}
            <Img fluid={lang === 'en' ? data.img1.childImageSharp.fluid : data.img1ar.childImageSharp.fluid} alt="almootamayezoon" style={lang === 'en' ? { width: "95%", margin:"0 auto" } : { width: "75%", margin:"0 auto" }} />
          </div>
          <div className="col-10 col-md-7 col-lg-4 align-self-center my-3 my-lg-0">
            {/* <img src={img2} alt="sweetness" width="75%" /> */}
            <Img fluid={data.img2.childImageSharp.fluid} alt="sweetness" style={{ width: "75%", margin:"0 auto" }} />
          </div>
          <div className="col-10 col-md-7 col-lg-4 align-self-center my-3 my-lg-0">
            {/* <img src={img3} alt="fancy" width="40%" /> */}
            <Img fluid={data.img3.childImageSharp.fluid} alt="fancy" style={{ width: "40%", margin:"0 auto" }} />
          </div>
        </div>
        <div className="row mb-5 mt-5 mt-lg-0 justify-content-center text-center">
          <div className="col-3 col-md-2 col-lg-1 align-self-center">
            <a href="https://www.facebook.com/almootamayezoon/" target="_blank" rel="noopener noreferrer"><img src={Facebook} alt="facebook" width="20%" /></a>
          </div>
          {/* <div className="col-3 col-md-2 col-lg-1 align-self-center">
            <img src={Twitter} alt="twitter" width="30%" />
          </div>
          <div className="col-3 col-md-2 col-lg-1 align-self-center">
            <img src={LinkedIn} alt="linkedIn" width="30%" />
          </div> */}
        </div>
        <p className="subtitle text-center text-white pb-3">All rights are reserved © 2019</p>
      </div>
  )
}

export default Footer;