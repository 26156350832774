import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../images/products/loader'

const Loader = (props) => {

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMinYMid meet'
    }
  };

  return(
    <div style={{width: "100%", height: props.height}}>
      <Lottie options={defaultOptions} width="100%" height="100%" />
    </div>
  )
}

export default Loader;