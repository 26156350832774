import React, { useState, useContext } from "react"
import { Link } from "gatsby"
import { Navbar, Nav } from 'react-bootstrap';
import MenuLogo from '../images/navbar/MenuLogo.svg'
import MenuLogoAr from '../images/navbar/LogoArabic_header.svg'
import { LangContext } from "../context/langContext"
import { FormattedMessage } from 'react-intl'
import { useMediaQuery } from 'react-responsive'


const NavBar = () => {

  const { lang, toggleLang } = useContext(LangContext);
	const [expanding, setExpanding] = useState(false);
	const md = useMediaQuery({ query: '(max-width: 992px)' });
  const activeStyles = md?{color: '#FABC00'}:{color: '#FABC00',borderTop: '8px solid #FABC00'};

  return(
		<Navbar collapseOnSelect fixed="top" expand="lg" expanded={expanding} variant="light" onToggle={()=> setExpanding(!expanding)}>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" className={expanding?"hamburger hamburger--3dy d-inline d-lg-none is-active":"hamburger hamburger--3dy d-inline d-lg-none"} type="button">
				<span className="hamburger-box">
					<span className="hamburger-inner"></span>
				</span>
			</Navbar.Toggle>
			<Navbar.Collapse id="responsive-navbar-nav">
				<Nav className={lang==='en'?"mr-auto subtitle":"ml-auto subtitle"}>
					<img src={lang==='en'?MenuLogo:MenuLogoAr} alt="Logo" width="65%" className="d-inline-block d-lg-none mb-5 mx-auto"/>
					<Link to="/" className="nav-link subtitle" activeStyle={activeStyles} ><FormattedMessage id="homeLink"/></Link>
					<Link to="/products/" className="nav-link subtitle" activeStyle={activeStyles} ><FormattedMessage id="productsLink"/></Link>
					{/* <Link to="/factory/" className="nav-link subtitle" activeStyle={activeStyles} ><FormattedMessage id="factoryLink"/></Link> */}
					<Link to="/about/" className="nav-link subtitle" activeStyle={activeStyles} ><FormattedMessage id="aboutLink"/></Link>
					<Link to="/contact/" className="nav-link subtitle" activeStyle={activeStyles} ><FormattedMessage id="contactLink"/></Link>
					<button className="subtitle langBtn d-block d-lg-none" onClick={() => toggleLang()}><FormattedMessage id="language"/></button>
				</Nav>
				<Nav>
					<button className="subtitle langBtn d-none d-lg-block" onClick={() => toggleLang()}><FormattedMessage id="language"/></button>
				</Nav>
			</Navbar.Collapse>
			<Navbar.Brand>
				<Link to="/"><img src={lang==='en'?MenuLogo:MenuLogoAr} alt="" className="navbarBrandLogo d-inline-block" /></Link>
			</Navbar.Brand>
		</Navbar>
  )
}

export default NavBar;